form {
  h2 {
    font-size: $font-small;
    font-weight: bold;
  }

  fieldset {
    padding: 0;
    display: grid;
    grid-gap: 1rem;
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 768px) {
    fieldset {
      &:first-of-type,
      &:nth-last-of-type(2) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

label {
  display: block;
}

/*
Delete these "name" selectors once we fix html-loader and can target type="text"
  See TODO in webpack.common.js
*/
// input[type="text"],
input[name="firstname"],
input[name="lastname"],
input[name="company"],
input[name="website"],
/* End TODO */

input[type="url"],
input[type="phone"],
input[type="email"] {
  border-radius: 0;
  border: none;
  display: block;
  font-family: monospace;
  font-size: 1rem;
  padding: 1.5rem 1rem;
  width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;

  & + label {
    cursor: pointer;
    font-size: 0.9rem;
    letter-spacing: 0.15rem;
    padding: 1.5rem 1rem;

    &:first-of-type {
      margin-left: 0;
    }

    @media screen and (min-width: $screen-medium) {
      text-align: center;
    }
  }
}

textarea {
  border-radius: 0;
  border: 1px solid transparent;
  display: block;
  font-size: 1.1rem;
  padding: 1rem;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
textarea {
  &::placeholder {
    color: $color-true-black;
    opacity: 1;
  }

  &:focus {
    outline: 1px solid var(--theme-color, $color-true-black);
  }
}

input,
textarea,
input[type="radio"] + label {
  background-color: $color-off-white;
  color: inherit;
}

input[type="radio"] {
  & + label {
    background-color: $color-off-white;
    color: inherit;
    transition: background-color 300ms ease, color 300ms ease;
  }

  & + label:hover,
  &:checked + label {
    background-color: var(--theme-color, $color-true-black);
    color: $color-white;
  }
}

input[type="submit"] {
  border-radius: none;
  display: block;
  cursor: pointer;
  margin-top: 1rem;
  padding: 2rem 0;
  width: 100%;
  -webkit-appearance: none;
}

.radio-wrapper {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @media screen and (min-width: 568px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.form-messages {
  background-color: transparent;
  transition: background-color 300ms ease;

  &:not(:empty) {
    color: $color-black;
    margin-top: 1rem;
    padding: 2rem;
    text-align: center;

    &.success {
      background-color: $color-mint;
    }

    &.error {
      background-color: $color-red;
    }

    p {
      margin-bottom: 0;
    }
  }
}
