.mobile-nav-button {
  color: $color-black;
  cursor: pointer;
  display: inline-block;
  font-size: 4rem;
  padding: 1.5rem $space-medium;

  @media screen and (min-width: $screen-medium) {
    display: none;
  }
}

header nav {
  width: 100%;
}

nav {
  text-align: right;

  &.active {
    background-color: transparent;
    height: 100%;
    top: 0;

    .link-wrap {
      background: $color-white;
      height: 100%;
      left: 0;
      opacity: 1;
      overflow-y: scroll;
      padding-top: 1rem;
      pointer-events: all;
      position: fixed;
      width: 100%;
      z-index: $z-index-nav-active-link-wrap;

      a {
        display: inline-block;
        color: inherit;
        opacity: 1;
      }

      span {
        display: block;
      }
    }
  }

  a:hover,
  a:active {
    cursor: pointer;
  }

  .link-wrap {
    opacity: 0;
    pointer-events: none;
    text-align: center;

    a {
      color: inherit;
      display: none;
      font-size: 1.15rem;
      letter-spacing: 0.25rem;
      margin: 0.5rem auto;
      padding: 1.5rem;
      width: 60%;
      text-transform: uppercase;
      text-decoration: none;
      transition: background-color 300ms ease, color 300ms ease;

      &:hover,
      &:active {
        background-color: $color-black;
        color: $color-white;
      }

      @media screen and (min-width: $screen-medium) {
        &:last-of-type,
        &:nth-last-of-type(2) {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: $screen-medium) {
    font-size: 1rem;
    letter-spacing: 0.2rem;
    padding: 3rem;
    text-align: right;
    text-transform: uppercase;

    a {
      display: inline-block;
      padding: 0.5rem 1rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .link-wrap {
      font-size: initial;
      opacity: 1 !important; // fix for gsap animation
      pointer-events: all;
      text-align: inherit;

      a {
        border: none;
        color: inherit;
        display: inline-block;
        font-size: 1rem;
        padding: 2rem;
        text-decoration: none;
        text-transform: none;
        text-transform: uppercase;
        width: auto;

        &:last-child {
          margin-right: 1rem;
        }

        &:hover,
        &:active {
          background-color: var(--theme-color, $color-true-black);
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }
}

html[style^="--theme-color"] nav .link-wrap a {
  &:hover,
  &:active {
    background-color: var(--theme-color, $color-true-black);
    color: $color-white;
  }
}
