::selection,
::-moz-selection {
  background-color: var(--theme-color, $color-white);
  color: $color-black;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: inherit;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

html,
body {
  background-color: $color-white;
  color: $color-black;
  font-size: 13px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

html {
  box-sizing: border-box;

  &[data-theme-color] {
    color: var(--theme-color) !important;
  }
}

body {
  font-size: var(--font-base, 13px);
  font-family: var(--font-family-base, monospace);
  position: relative;
  height: 100%;
}

a {
  color: inherit;

  &:hover,
  &:active {
    color: var(--theme-color, inherit);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: lighter;
  margin-bottom: 1rem;
  text-align: inherit;

  &:only-child {
    margin: 0;
  }
}

h1 {
  font-size: $font-xlarge;
}

h2 {
  font-size: $font-large;
}

h3 {
  font-size: $font-medium;
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
}

h5 {
  font-size: 1rem;
}

b,
strong {
  font-weight: bold;
}

hr {
  background-color: inherit;
  display: inline-block;
  height: 1px;
  margin: $space-small auto;
  width: 100%;

  @media screen and (min-width: $screen-medium) {
    margin: $space-medium auto;
  }

  &.dashed {
    border-bottom: 2px dashed;
    border-color: inherit;
  }

  &.spacer {
    margin: 0;

    &.small {
      height: $space-small;
    }

    &.medium {
      height: $space-medium;
    }

    &.large {
      height: $space-large;
    }
  }
}

p,
ul li {
  font-size: $font-normal;
  letter-spacing: 0.01rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}

img {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  max-width: 100%;
}

header,
footer {
  position: fixed;
}

header {
  align-items: center;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: $z-index-header;
}

section {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  &.bordered-top {
    border-top: 1px dotted;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: $screen-medium) {
      flex-wrap: nowrap;
    }
  }

  [class^='col-'] {
    flex-basis: 100%;
    width: 100%;
    height: 100%;
  }

  @media (min-width: $screen-medium) {
    flex-direction: row;

    $widths: (25, 33, 50, 66, 75, 100);

    @each $percent, $i in $widths {
      .col-#{$percent} {
        flex-basis: $percent * 1%;
      }
    }
  }
}

footer {
  align-items: center;
  background-color: $color-white;
  bottom: 0;
  color: $color-black;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0;
  padding: 0 2.5rem 0 1rem;
  position: fixed;
  text-transform: uppercase;
  width: 100%;
  z-index: $z-index-footer;

  @media screen and (min-width: 400px) {
    padding-right: 3rem;
    text-align: center;
  }

  span {
    display: inline-block;
    letter-spacing: 0.15rem;
    line-height: 3rem;

    label {
      color: inherit;
    }
  }

  nav {
    padding: 1.5rem;
    display: none;

    @media screen and (min-width: $screen-medium) {
      display: block;
    }

    a {
      font-size: 0.85rem;
      text-decoration: none;
      color: inherit;
    }
  }
}

canvas {
  height: 100%;
  max-width: 100%;
  opacity: 0;
  width: 100%;
}
