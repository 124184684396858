.main {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: $space-xxlarge 0 $space-large 0;
  position: absolute;
  right: 0;
  width: 100%;

  @media screen and (min-width: $screen-medium) {
    padding: $space-xlarge 0 $space-large 0;
  }

  section {
    padding: $space-small 0;

    &:first-child {
      padding-top: 0;

      h1 {
        border-bottom: 2px solid;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
      }
    }

    @media screen and (min-width: $screen-medium) {
      padding: $space-medium 0;

      &:first-of-type {
        padding-top: $space-medium;
      }
    }

    &.cta {
      background-color: $color-true-black;
      color: $color-white;
      padding: $space-medium 0;

      @media screen and (min-width: $screen-medium) {
        padding: $space-medium;
      }
    }
  }
}

.webgl {
  cursor: pointer;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  z-index: $z-index-canvas;
}

.canvas-wrapper {
  min-height: 30vh;
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.loading-bar {
  background: $color-white;
  height: 2px;
  position: absolute;
  top: 50%;
  transform-origin: center left;
  transform: scaleX(0.3);
  transition: transform 0.5s;
  width: 100%;
}

.loading-bar.ended {
  background: $color-white;
  transform-origin: 100% 0;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
}

.text-center {
  text-align: center;
}


.header-logo {
  display: inline-block;
  overflow: hidden;
  padding: 1.5rem;
  width: 12rem;

  &:hover {
    svg {
      background-color: var(--theme-color, $color-black);

      & > * {
        fill: $color-white;
      }
    }
  }

  svg {
    height: 100%;
    width: 100%;
    transition: background-color 300ms ease;

    & > * {
      transition: fill 300ms ease;
    }
  }
}

.dark-mode .header-logo {
  svg > * {
    fill: $color-white;
  }
}

html[style^="--theme-color"] .header-logo:hover svg > * {
  fill: '#000000';
}

.logo {
  align-self: center;
  font-size: 7vw;
  letter-spacing: 4vw;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: "";
    display: inline-block;
    width: 4vw;
  }
}

.content-wrapper {
  color: $color-white;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: background-color 300ms ease;
  width: 100%;
  z-index: $z-index-content-wrapper;

  &.active {
    background-color: rgba(10, 10, 10, 0.95);
    display: initial;
    pointer-events: all;
    z-index: $z-index-content-wrapper-active;

    @media screen and (min-width: $screen-medium) {
      z-index: $z-index-content-wrapper-active;
    }
  }
}

.col-wrap {
  display: flex;
  flex-direction: column;

  &.mobile-reversed {
    flex-direction: column-reverse;
  }

  @media screen and (min-width: $screen-medium) {
    display: flex;
    flex-direction: row;
    max-width: $screen-medium;
    width: 100%;

    &.mobile-reversed {
      flex-direction: row;
    }

    &.narrow {
      max-width: $screen-small;
    }

    &.wide {
      max-width: $screen-large;
    }

    &.xwide {
      max-width: $screen-xlarge;
    }
  }
}

.content {
  width: 100%;
  padding: 0 $space-small;

  @media screen and (min-width: $screen-medium) {
    margin: 0 auto;
    max-width: 80%;
    padding: $space-medium;

    &.narrow {
      max-width: 75%;
    }

    &.wide {
      margin: 0 auto;
      width: 90%;
      padding: 0;
    }
  }

  ul {
    font-size: 1.35rem;
    letter-spacing: 0.01rem;
    line-height: 1.75rem;
    list-style-type: square;
    margin-bottom: 2rem;
    margin: 0 0 0 1rem;
    padding: 0;

    li {
      margin: 1rem 0;
      word-break: break-word;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      ul {
        list-style-type: circle;
        margin-top: 1rem;
      }
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  .card {
    background: $color-true-black;
    color: $color-white;
    padding: 1.5rem;
    margin-bottom: 2rem;

    &.overlap-above {
      margin-top: -4rem;
      position: relative;
    }

    h1 {
      font-weight: bold;
      border: none !important;
      padding: 0;
      margin: 0;
      position: relative;
    }

    & + h3 {
      margin-top: 2rem;
    }
  }
}

.svg-wrapper {
  margin: 0 auto;
  max-width: 60%;
  overflow: hidden;
  padding: $space-medium;
  text-align: center;
  width: 100%;

  @media screen and (min-width: $screen-medium) {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
  }

  svg {
    display: inline-block;
    height: 100%;
    width: 100%;
    max-width: 300px;
  }
}
