.no-js {
  body {
    opacity: 0;
    -webkit-animation: fadeIn 1000ms ease 500ms 1 normal forwards;
    animation: fadeIn 1000ms ease 500ms 1 normal forwards;
  }

  .webgl {
    width: 100%;
  }

  .logo {
    background-color: $color-true-black;
    color: $color-white;
    font-size: 5vw;
    margin-left: -2vw;
    padding: $space-medium 0;
  }

  .content-wrapper,
  .content {
    display: block;
    opacity: 1;
  }

  .content {
    margin: 0 auto 5% auto;
    padding: 0 3.5rem;

    &:first-of-type {
      margin: 5% auto;
      padding: 3.5rem;
    }
  }

  .content-wrapper {
    opacity: 1;
    pointer-events: all;
    z-index: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
