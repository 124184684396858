.button {
  background-color: $color-white;
  color: $color-true-black;
  outline: 1px solid $color-true-black;
  display: inline-block;
  font-size: $font-small;
  line-height: $space-medium;
  margin-top: $space-small;
  padding: $space-small $space-medium;
  text-decoration: none;
  transition: background-color 300ms ease, color 300ms ease;

  &:hover {
    background-color: var(--theme-color, $color-true-black);
    color: $color-white;
    outline: 1px solid $color-white;
  }
}
