.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-uppercase {
  text-transform: uppercase;
}

[aria-disabled="true"] {
  pointer-events: none;
  color: red;
}

.mb-0 {
  margin-bottom: 0;
}
