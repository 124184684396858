// Colors
$color-black: #16161d; // Eigengrau
$color-gray: #888888;
$color-mint: #c0ffe3;
$color-off-white: #f1f1f1;
$color-red: tomato;
$color-true-black: #000000;
$color-white: #ffffff;

// Breakpoints
$screen-small: 400px;
$screen-medium: 768px;
$screen-large: 1200px;
$screen-xlarge: 1800px;

// Spacing
$space-xsmall: 0.75rem;
$space-small: 1.5rem;
$space-medium: 3rem;
$space-large: 6rem;
$space-xlarge: 8rem;
$space-xxlarge: 10rem;
$space-xxxlarge: 12rem;

// Z Index
$z-index-header: 4;
$z-index-footer: 4;
$z-index-canvas: 0; /* works */

$z-index-nav-active-after: -1;
$z-index-nav-active-link-wrap: 2;
$z-index-content-wrapper: -1;
$z-index-content-wrapper-active: 2;
$z-index-darkmode-toggle: 2;

:root {
  --font-family-base: "Courier", "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
  --font-base: 13px;
  --font-xlarge: 2.5rem;
  --font-large: 2rem;
  --font-medium: 1.75rem;
  --font-small: 1.5rem;
  --font-normal: 1rem;

  @media screen and (min-width: $screen-medium) {
    --font-xlarge: 3rem;
    --font-large: 2.5rem;
    --font-medium: 2rem;
    --font-small: 1.5rem;
    --font-normal: 1.25rem;
  }
}

$font-xlarge: var(--font-xlarge);
$font-large: var(--font-large);
$font-medium: var(--font-medium);
$font-small: var(--font-small);
$font-normal: var(--font-normal);
