.dark-mode {
  ::selection,
  ::-moz-selection {
    background-color: var(--theme-color, $color-true-black);
    color: $color-white;
  }

  input,
  textarea {
    &::placeholder {
      color: $color-white;
    }

    &:focus {
      outline-color: var(--theme-color, $color-white);
    }
  }

  input,
  textarea,
  input[type="radio"] + label {
    background-color: $color-black;
    color: $color-white;
  }

  input[type="radio"] + label:hover,
  input[type="radio"]:checked + label {
    background-color: var(--theme-color, $color-white);
    color: $color-true-black;
  }

  @media screen and (max-width: $screen-medium) {
    nav .link-wrap span {
      background-color: $color-black;
      color: $color-white;
    }
  }

  .content {
    *::selection,
    *::-moz-selection {
      background-color: var(--theme-color, $color-black);
      color: $color-white;
    }

    ul {
      & *::selection,
      & *::-moz-selection {
        background-color: var(--theme-color, $color-white);
        color: $color-black;
      }
    }

    .card {
      background-color: $color-white;
      color: $color-black;
    }
  }
}

input[type="checkbox"] {
  & + label {
    background: none;
    border-radius: 0;
    font-size: 3rem;
    text-align: center;
    padding: 0.25rem;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.dark-mode input[type="checkbox"] {
  & + label {
    color: inherit;
  }
}

.dark-mode-toggle {
  color: inherit;
  float: left;
  margin-right: auto;
  opacity: 1;
  padding: 1.5rem;
  transform: scale(1);
  transition: opacity 300ms ease, transform 300ms ease;
}

.no-js .dark-mode-toggle {
  opacity: 0;
  transform: scale(0);
}

.no-dark-mode {
  position: relative;
}

.dark-mode {
  body,
  header,
  footer {
    background-color: $color-true-black;
  }

  body,
  header,
  footer,
  .mobile-nav-button {
    color: $color-white;
  }

  nav.active .link-wrap {
    background-color: $color-true-black;

    a:hover,
    a:active {
      background-color: $color-white;
      color: $color-black;
    }
  }

  .header-logo {
    &:hover {
      svg {
        background-color: var(--theme-color, $color-white);

        & > * {
          fill: $color-true-black;
        }
      }
    }
  }

  [fill="#000"] {
    fill: $color-white;
  }

  [fill="#fff"] {
    fill: $color-true-black;
  }

  [stroke="#000"] {
    stroke: $color-white;
  }

  [stroke="#fff"] {
    stroke: $color-true-black;
  }

  .main section.cta {
    background-color: $color-white;
    color: $color-true-black;
  }

  .button {
    background-color: $color-true-black;
    color: $color-white;
    outline: 1px solid $color-white;

    &:hover {
      background-color: var(--theme-color, $color-white);
      color: $color-true-black;
      outline: 1px solid $color-true-black;
    }
  }

  nav {
    @media screen and (min-width: $screen-medium) {
      .link-wrap a {
        &:hover,
        &:active {
          background-color: $color-white;
          color: $color-true-black;
        }
      }
    }
  }
}

html[style^="--theme-color"].dark-mode {
  .header-logo {
    &:hover {
      svg {
        background-color: var(--theme-color, $color-true-black);

        & > * {
          fill: $color-white;
        }
      }
    }
  }

  nav.active .link-wrap {
    background-color: $color-true-black;

    a:hover,
    a:active {
      background-color: var(--theme-color, $color-true-black);
      color: $color-white;
    }
  }

  input[type="radio"] + label:hover,
  input[type="radio"]:checked + label,
  .button:hover {
    color: $color-white;
  }
}
